import React, { useState, useEffect, useRef } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const navbarRef = useRef(null);



  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const closeMenuAndPopup = () => {
    setIsMenuOpen(false);
  };


  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="navbar-container">
        <a href="/">
        <img
          src="tree.png"
          alt="Logo"
          className="navbar-logo"
          style={{ height: '55px', width: '55px' }}
        />
        </a>
        <div className={`navbar-menu-icon ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div className="icon-bar"></div>
          <div className="icon-bar"></div>
          <div className="icon-bar"></div>
        </div>
        <ul className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
          <li className="navbar-item">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="navbar-link"
              onClick={closeMenuAndPopup}
            >
              Home
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="navbar-link"
              onClick={closeMenuAndPopup}
            >
              About
            </Link>
          </li>
          <li className="navbar-item">
            <Link
              activeClass="active"
              to="Projects"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="navbar-link"
              onClick={closeMenuAndPopup}
            >
              Projects
            </Link>
          </li>
          <li className="navbar-item">
            <a href='https://shop.dreamyoak.xyz' target='_blank' className="navbar-link" >
              Shop
            </a>
          </li>
        </ul>
      </div>

    </nav>
  );
};

export default Navbar;
